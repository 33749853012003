import React, { useState, useCallback, useRef, useContext, useEffect } from "react";
import axios from "axios";
import LaunchpadType from "./LaunchpadType";
import PresaleInformation from "./PresaleInformation";
import PresaleInformation2 from "./PresaleInformation2";
import ProjectInformation from "./ProjectInformation";
import TokenDistribution from "./TokenDistribution";
import TokenInformation from "./TokenInformation";
import CreatePresale from "./CreatePresale";
import Header from "../../Components/Comman/Header";
import Sidebar from "../../Components/Comman/Sidebar";
import Footer from "../../Components/Comman/Footer";
import { centecxContext } from "../../CentcexContext";
import { constants, ethers } from "ethers";
import PresaleModal from "../../Components/Comman/PresaleModal";
import { checkAllowance, getReservesNonFixed, getRouter, giveAllowance } from "../../functions/etherFunctions";
import { useNavigate } from "react-router-dom";
import { validateUrl } from "../../functions/commonFunction";

export default function Createpree() {
  const navigate = useNavigate();
  const { networkInfo, selecteChain, toggleSidebar, settingData, signature } = useContext(centecxContext);
  const [launchpadType, setLaunchpadType] = useState(0);

  const [presaleLoader, setPresaleLoader] = useState(false);

  const [contractAddress, setContractAddress] = useState("");
  const [tokenInfo, setTokenInfo] = useState(null);

  const [tokenCalc, setTokenCalc] = useState({
    token: 0,
    tokenPer: 0,
  });
  const [enableVeste, setenableVeste] = useState(false);
  const [vestPer, setVestPer] = useState("");
  const [vestTime, setVestTime] = useState("");
  const [vestPeriod, setVestPeriod] = useState({ name: "", value: 0 });

  const [liquidityAmt, setLiquidityAmt] = useState(null);

  const [estimateGas, setEstimateGas] = useState("");

  const [contractAllowance, setContractAllowance] = useState(0);

  const [presaleInfo, setPresaleInfo] = useState(null);
  const [presaleRate, setPresaleRate] = useState("");

  const [listingRate, setListingRate] = useState("");
  const [softCap, setSoftCap] = useState("");
  const [hardCap, setHardCap] = useState("");
  const [minContribution, setMinContribution] = useState("");
  const [maxContribution, setMaxContribution] = useState("");

  const [dex, setDex] = useState("");
  const [routerContract, setRouterContract] = useState(null);

  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const inputRef = useRef();
  const dropZoneRef = useRef();
  const [file1, setFile1] = useState(null);
  const [previewSrc, setPreviewSrc] = useState(null);
  const [description, setDescription] = useState("");
  const [selectInterval, setSelectInterval] = useState("");
  const inputRef2 = useRef();
  const dropZoneRef2 = useRef();
  const [file2, setFile2] = useState(null);
  const [previewSrc2, setPreviewSrc2] = useState(null);
  const [inputErrors, setInputErrors] = useState({});
  const [toggleSocialMedia, setToggleSocialMedia] = useState([
    { name: "Website Link", toggle: true },
    { name: "Instagram Link", toggle: false },
    { name: "Facebook Link", toggle: false },
    { name: "Youtube Link", toggle: false },
    { name: "Tiktok Link", toggle: false },
    { name: "Twitter Link", toggle: false },
    { name: "Telegram Link", toggle: false },
  ]);
  const [socialMedia, setSocialMedia] = useState({
    website: "",
    instagram: "",
    facebook: "",
    youtube: "",
    tiktok: "",
    twitter: "",
    telegram: "",
  });

  const [selectedTokenOne, setSelectedTokenOne] = useState(null);

  const [error, setError] = useState("");

  useEffect(() => {
    if (launchpadType === 0) {
      setListingRate("");
    } else {
      setListingRate("0");
      setDex(null);
      setRouterContract(null);
      setSelectInterval("");
    }
    setSelectedTokenOne(null);
  }, [launchpadType]);

  const handleSocialMedia = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let errStr = name + "Err";
    if (!value) {
      setInputErrors((prev) => ({ ...prev, [errStr]: `${name} is required` }));
    } else {
      if (!validateUrl(value)) {
        setInputErrors((prev) => ({ ...prev, [errStr]: "Invalid url" }));
      } else {
        setInputErrors((prev) => ({ ...prev, [errStr]: "" }));
      }
    }
    setSocialMedia((prev) => ({ ...prev, [name]: value }));
  };

  const handleToggleSocialMedia = (index, value) => {
    let cpy = [...toggleSocialMedia];
    cpy[index].toggle = value;
    setToggleSocialMedia(cpy);
  };

  const updateThumbnail = useCallback((file) => {
    let thumbnailElement = dropZoneRef.current.querySelector(".drop-zone__thumb");

    if (dropZoneRef.current.querySelector(".drop-zone__prompt")) {
      dropZoneRef.current.querySelector(".drop-zone__prompt").remove();
    }

    if (!thumbnailElement) {
      thumbnailElement = document.createElement("div");
      thumbnailElement.classList.add("drop-zone__thumb");
      dropZoneRef.current.appendChild(thumbnailElement);
    }

    thumbnailElement.dataset.label = file.name;
    thumbnailElement.style.backgroundImage = "none";

    if (file.type.startsWith("image/")) {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = () => {
        setPreviewSrc(reader.result);
      };
    }
  }, []);

  const handleDrop = useCallback(
    (e) => {
      e.preventDefault();
      if (e.dataTransfer.files.length) {
        setFile1(e.dataTransfer.files[0]);
        inputRef.current.files = e.dataTransfer.files;
        updateThumbnail(e.dataTransfer.files[0]);
      }

      dropZoneRef.current.classList.remove("drop-zone--over");
    },
    [updateThumbnail]
  );

  const handleDragLeaveAndEnd = useCallback((e) => {
    dropZoneRef.current.classList.remove("drop-zone--over");
  }, []);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    dropZoneRef.current.classList.add("drop-zone--over");
  }, []);

  const handleChangeThumbnail1 = useCallback(
    (e) => {
      if (e.target.files.length) {
        updateThumbnail(e.target.files[0]);
      }
    },
    [updateThumbnail]
  );
  const handleClick = useCallback((e) => {
    setPreviewSrc(null);
    inputRef.current.click();
  }, []);

  const updateThumbnail2 = useCallback((file) => {
    let thumbnailElement2 = dropZoneRef2.current.querySelector(".drop-zone__thumb");

    if (dropZoneRef2.current.querySelector(".drop-zone__prompt")) {
      dropZoneRef2.current.querySelector(".drop-zone__prompt").remove();
    }

    if (!thumbnailElement2) {
      thumbnailElement2 = document.createElement("div");
      thumbnailElement2.classList.add("drop-zone__thumb");
      dropZoneRef2.current.appendChild(thumbnailElement2);
    }

    thumbnailElement2.dataset.label = file.name;
    thumbnailElement2.style.backgroundImage = "none";

    if (file.type.startsWith("image/")) {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = () => {
        setPreviewSrc2(reader.result);
      };
    }
  }, []);

  const handleDrop2 = useCallback(
    (e) => {
      e.preventDefault();

      if (e.dataTransfer.files.length) {
        setFile2(e.dataTransfer.files[0]);
        inputRef2.current.files = e.dataTransfer.files;
        updateThumbnail2(e.dataTransfer.files[0]);
      }

      dropZoneRef2.current.classList.remove("drop-zone--over");
    },
    [updateThumbnail2]
  );

  const handleDragLeaveAndEnd2 = useCallback((e) => {
    dropZoneRef2.current.classList.remove("drop-zone--over");
  }, []);

  const handleDragOver2 = useCallback((e) => {
    e.preventDefault();
    dropZoneRef2.current.classList.add("drop-zone--over");
  }, []);

  const handleChangeThumbnail2 = useCallback(
    (e) => {
      if (e.target.files.length) {
        updateThumbnail2(e.target.files[0]);
      }
    },
    [updateThumbnail2]
  );

  const handleClick2 = useCallback((e) => {
    setPreviewSrc2(null);
    inputRef2.current.click();
  }, []);

  const removeButtonRef = useRef();
  removeButtonRef.current = (src) => {
    if (src === "previewSrc") {
      setPreviewSrc(null);
    } else if (src === "previewSrc2") {
      setPreviewSrc(null);
    }
  };

  const removeButtonRef2 = useRef();
  removeButtonRef2.current = (src) => {
    if (src === "previewSrc") {
      setPreviewSrc2(null);
    } else if (src === "previewSrc2") {
      setPreviewSrc2(null);
    }
  };

  const formats = ["header", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent", "link", "image"];

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
      ["link", "image"],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const resetInputs = () => {
    setLaunchpadType(0);
    setPresaleLoader(false);
    setContractAddress("");
    setTokenInfo(null);
    setPresaleInfo(null);
    setPresaleRate("");
    setListingRate("");
    setSoftCap("");
    setHardCap("");
    setMinContribution("");
    setMaxContribution("");
    setDex(null);
    setStartTime("");
    setEndTime("");
    setFile1(null);
    setPreviewSrc(null);
    setDescription("");
    setSelectInterval("");
    setFile2(null);
    setPreviewSrc2(null);
    setInputErrors({});
    setToggleSocialMedia([
      { name: "Website Link", toggle: true },
      { name: "Instagram Link", toggle: false },
      { name: "Facebook Link", toggle: false },
      { name: "Youtube Link", toggle: false },
      { name: "Tiktok Link", toggle: false },
      { name: "Twitter Link", toggle: false },
    ]);
    setSocialMedia({
      website: "",
      instagram: "",
      facebook: "",
      youtube: "",
      tiktok: "",
      twitter: "",
    });
  };

  const handleRouter = async (item) => {
    if (item) {
      const router = await getRouter(item?.address, networkInfo?.signer);
      if (router) {
        setRouterContract(router);
      } else {
        setRouterContract(null);
      }
    }
  };

  const handleSelectLiuidPer = async () => {
    try {
      let token = (Number(tokenInfo?.totalSupply) * Number(selectInterval)) / 100;
      setLiquidityAmt((prev) => ({ ...prev, ether: token * Number(listingRate), token: token }));
    } catch {
      setLiquidityAmt(null);
    }
  };

  const calculateLiquidityToken = async () => {
    let amountETHDesired = 0;
    if (selectInterval !== "") {
      amountETHDesired = (Number(hardCap) * Number(selectInterval)) / 100;
    }
    if (!tokenInfo?.pair) {
      let token = amountETHDesired / Number(listingRate);
      setLiquidityAmt((prev) => ({ ...prev, token: token }));
    } else {
      const reserve = await getReservesNonFixed(
        networkInfo?.wethAddress,
        contractAddress,
        networkInfo?.factory,
        networkInfo?.signer,
        networkInfo?.connecteAddress
      );
      let token = (amountETHDesired * Number(reserve[1])) / Number(reserve[0]);
      setLiquidityAmt((prev) => ({ ...prev, token: token }));
    }
  };

  useEffect(() => {
    calculateLiquidityToken();
  }, [selectInterval, listingRate, tokenInfo, hardCap, networkInfo?.factory]);

  useEffect(() => {
    if (presaleLoader) {
      window.addEventListener("beforeunload", function (e) {
        e.preventDefault();
      });
    }
  }, [presaleLoader]);

  useEffect(() => {
    if (selectedTokenOne?.address?.toLowerCase() === contractAddress) {
      setInputErrors((prev) => ({
        ...prev,
        selectTokenErr: "This token is already selected for staking, please select another token or native coin.",
      }));
    } else {
      setInputErrors((prev) => ({
        ...prev,
        selectTokenErr: "",
      }));
    }
  }, [selectedTokenOne]);

  const createPresale = async () => {
    try {
      setError("");
      let formData = new FormData();
      let temp = true;
      if (!selectedTokenOne && launchpadType === 1) {
        setInputErrors((prev) => ({
          ...prev,
          selectTokenErr: "Select token or coins.",
        }));
        temp = false;
      } else {
        if (selectedTokenOne?.address?.toLowerCase() === contractAddress) {
          setInputErrors((prev) => ({
            ...prev,
            selectTokenErr: "This token is already selected for staking, please select another token or native coin.",
          }));
          temp = false;
        } else {
          setInputErrors((prev) => ({
            ...prev,
            selectTokenErr: "",
          }));
        }
      }
      if (!contractAddress) {
        setInputErrors((prev) => ({
          ...prev,
          contractAddressErr: "Contract address is require.",
        }));
        temp = false;
      } else {
        if (!tokenInfo) {
          setInputErrors((prev) => ({
            ...prev,
            contractAddressErr: "Invalid contract address.",
          }));
          temp = false;
        } else {
          setInputErrors((prev) => ({ ...prev, contractAddressErr: "" }));
        }
      }
      if (!presaleRate) {
        setInputErrors((prev) => ({
          ...prev,
          presaleRateErr: "Presale rate is require.",
        }));
        temp = false;
      } else {
        if (presaleRate <= 0) {
          setInputErrors((prev) => ({
            ...prev,
            presaleRateErr: `Number must be greater than or equal to 0`,
          }));
          temp = false;
        } else {
          setInputErrors((prev) => ({ ...prev, presaleRateErr: "" }));
        }
      }
      if (launchpadType === 0) {
        if (!listingRate) {
          setInputErrors((prev) => ({
            ...prev,
            listingRateErr: "Listing rate is require.",
          }));
          temp = false;
        } else {
          if (listingRate <= 0) {
            setInputErrors((prev) => ({
              ...prev,
              listingRateErr: `Number must be greater than or equal to 0`,
            }));
            temp = false;
          } else {
            setInputErrors((prev) => ({ ...prev, listingRateErr: "" }));
          }
        }
      }
      if (!softCap) {
        setInputErrors((prev) => ({
          ...prev,
          softCapErr: "Soft cap is require.",
        }));
        temp = false;
      } else {
        if (softCap <= 0) {
          setInputErrors((prev) => ({
            ...prev,
            softCapErr: `Number must be greater than or equal to 0`,
          }));
          temp = false;
        } else {
          setInputErrors((prev) => ({ ...prev, softCapErr: "" }));
        }
      }
      if (!hardCap) {
        setInputErrors((prev) => ({
          ...prev,
          hardCapErr: "Hard cap is require.",
        }));
        temp = false;
      } else {
        if (hardCap <= 0) {
          setInputErrors((prev1) => ({
            ...prev1,
            hardCapErr: `Number must be greater than or equal to 0.`,
          }));
          temp = false;
        } else if (Number(hardCap) < Number(softCap)) {
          setInputErrors((prev2) => ({
            ...prev2,
            hardCapErr: `HardCap is Must Be bigger than SoftCap .`,
          }));
          temp = false;
        } else if (Number(tokenInfo.totalSupply) < tokenCalc?.token) {
          setInputErrors((prev2) => ({
            ...prev2,
            hardCapErr: `Hard Cap is too high.`,
          }));
          temp = false;
        } else {
          setInputErrors((prev) => ({ ...prev, hardCapErr: "" }));
        }
      }
      if (!minContribution) {
        setInputErrors((prev) => ({
          ...prev,
          minContributionErr: "Minimum contribution is require.",
        }));
        temp = false;
      } else {
        if (Number(minContribution) <= 0) {
          setInputErrors((prev) => ({
            ...prev,
            minContributionErr: `Number must be greater than or equal to 0`,
          }));
          temp = false;
        } else {
          setInputErrors((prev) => ({ ...prev, minContributionErr: "" }));
        }
      }
      if (!maxContribution) {
        setInputErrors((prev) => ({
          ...prev,
          maxContributionErr: "Maximum contribution is require.",
        }));
        temp = false;
      } else {
        if (Number(maxContribution) <= 0) {
          setInputErrors((prev) => ({
            ...prev,
            maxContributionErr: `Number must be greater than or equal to 0`,
          }));
          temp = false;
        } else if (Number(maxContribution) < Number(minContribution)) {
          setInputErrors((prev) => ({
            ...prev,
            maxContributionErr: `Maximum Contribution has to be bigger than Minimum Contribution`,
          }));
          temp = false;
        } else {
          setInputErrors((prev) => ({ ...prev, maxContributionErr: "" }));
        }
      }
      if (launchpadType === 0) {
        if (!selectInterval || Number(selectInterval) < 0) {
          setInputErrors((prev) => ({
            ...prev,
            selectIntervalErr: "Please select liquidity rate.",
          }));
          temp = false;
        } else {
          setInputErrors((prev) => ({ ...prev, selectIntervalErr: "" }));
        }
        if (!dex || !dex?.name || !dex?.address) {
          setInputErrors((prev) => ({ ...prev, dexErr: "Please select dex." }));
          temp = false;
        } else {
          setInputErrors((prev) => ({ ...prev, dexErr: "" }));
        }
      }
      if (!startTime) {
        setInputErrors((prev) => ({
          ...prev,
          startTimeErr: "Please select presale start time.",
        }));
        temp = false;
      } else {
        let currentTimestamp = new Date().getTime();
        let selecteDateTimestamp = new Date(startTime).getTime();
        let validDateTimestamp = currentTimestamp + 1000 * 60 * 10;
        if (selecteDateTimestamp < validDateTimestamp) {
          setInputErrors((prev) => ({
            ...prev,
            startTimeErr: `Date must be at least 10 minutes into the future.`,
          }));
          temp = false;
        } else {
          setInputErrors((prev) => ({ ...prev, startTimeErr: "" }));
        }
        // setInputErrors((prev) => ({ ...prev, startTimeErr: "" }));
      }
      if (!endTime) {
        setInputErrors((prev) => ({
          ...prev,
          endTimeErr: "Please select presale end time.",
        }));
        temp = false;
      } else {
        let currentTimestamp = new Date().getTime();
        let selecteDateTimestamp = new Date(endTime).getTime();
        let validDateTimestamp = currentTimestamp + 1000 * 60 * 10;
        if (selecteDateTimestamp < validDateTimestamp) {
          setInputErrors((prev) => ({
            ...prev,
            endTimeErr: `Date must be at least 10 minutes into the future.`,
          }));
          temp = false;
          // setInputErrors((prev) => ({
          //   ...prev,
          //   endTimeErr: ``,
          // }));
        } else {
          let currentTimestamp = new Date(startTime).getTime();
          let selecteDateTimestamp = new Date(endTime).getTime();
          let validDateTimestamp = currentTimestamp + 1000 * 60 * 10;
          if (selecteDateTimestamp < validDateTimestamp) {
            setInputErrors((prev) => ({
              ...prev,
              endTimeErr: `End Time must be at least 10 minutes after the ${launchpadType === 0 ? "presale" : "staking"} Start Time.`,
            }));
            temp = false;
          } else {
            setInputErrors((prev) => ({ ...prev, endTimeErr: "" }));
          }
          // setInputErrors((prev) => ({ ...prev, endTimeErr: "" }));
        }
      }
      if (toggleSocialMedia[0]?.toggle) {
        if (!socialMedia?.website) {
          setInputErrors((prev) => ({
            ...prev,
            websiteErr: "website is require.",
          }));
          temp = false;
        } else {
          if (!validateUrl(socialMedia?.website)) {
            setInputErrors((prev) => ({ ...prev, websiteErr: "Invalid url." }));
            temp = false;
          } else {
            setInputErrors((prev) => ({ ...prev, websiteErr: "" }));
          }
        }
      }
      if (toggleSocialMedia[1]?.toggle) {
        if (!socialMedia?.instagram) {
          setInputErrors((prev) => ({
            ...prev,
            instagramErr: "instagram is require.",
          }));
          temp = false;
        } else {
          if (!validateUrl(socialMedia?.instagram)) {
            setInputErrors((prev) => ({ ...prev, instagramErr: "Invalid url." }));
            temp = false;
          } else {
            setInputErrors((prev) => ({ ...prev, instagramErr: "" }));
          }
        }
      }
      if (toggleSocialMedia[2]?.toggle) {
        if (!socialMedia?.facebook) {
          setInputErrors((prev) => ({
            ...prev,
            facebookErr: "facebook is require.",
          }));
          temp = false;
        } else {
          if (!validateUrl(socialMedia?.facebook)) {
            setInputErrors((prev) => ({ ...prev, facebookErr: "Invalid url." }));
            temp = false;
          } else {
            setInputErrors((prev) => ({ ...prev, facebookErr: "" }));
          }
        }
      }
      if (toggleSocialMedia[3]?.toggle) {
        if (!socialMedia?.youtube) {
          setInputErrors((prev) => ({
            ...prev,
            youtubeErr: "youtube is require.",
          }));
          temp = false;
        } else {
          if (!validateUrl(socialMedia?.youtube)) {
            setInputErrors((prev) => ({ ...prev, youtubeErr: "Invalid url." }));
            temp = false;
          } else {
            setInputErrors((prev) => ({ ...prev, youtubeErr: "" }));
          }
        }
      }
      if (toggleSocialMedia[4]?.toggle) {
        if (!socialMedia?.tiktok) {
          setInputErrors((prev) => ({
            ...prev,
            tiktokErr: "tiktok is require.",
          }));
          temp = false;
        } else {
          if (!validateUrl(socialMedia?.tiktok)) {
            setInputErrors((prev) => ({ ...prev, tiktokErr: "Invalid url." }));
            temp = false;
          } else {
            setInputErrors((prev) => ({ ...prev, tiktokErr: "" }));
          }
        }
      }
      if (toggleSocialMedia[5]?.toggle) {
        if (!socialMedia?.twitter) {
          setInputErrors((prev) => ({
            ...prev,
            twitterErr: "twitter is require.",
          }));
          temp = false;
        } else {
          if (!validateUrl(socialMedia?.twitter)) {
            setInputErrors((prev) => ({ ...prev, twitterErr: "Invalid url." }));
            temp = false;
          } else {
            setInputErrors((prev) => ({ ...prev, twitterErr: "" }));
          }
        }
      }
      if (toggleSocialMedia[6]?.toggle) {
        if (!socialMedia?.telegram) {
          setInputErrors((prev) => ({
            ...prev,
            telegramErr: "telegram is require.",
          }));
          temp = false;
        } else {
          if (!validateUrl(socialMedia?.telegram)) {
            setInputErrors((prev) => ({ ...prev, telegramErr: "Invalid url." }));
            temp = false;
          } else {
            setInputErrors((prev) => ({ ...prev, telegramErr: "" }));
          }
        }
      }
      if (!description) {
        setInputErrors((prev) => ({
          ...prev,
          descriptionErr: "Description is require.",
        }));
        temp = false;
      } else {
        setInputErrors((prev) => ({ ...prev, descriptionErr: "" }));
      }
      if (!file1) {
        setInputErrors((prev) => ({
          ...prev,
          file1Err: "Please selecte logo image.",
        }));
        temp = false;
      } else {
        if (file1.size / 1024 > 1024) {
          setInputErrors((prev) => ({
            ...prev,
            file1Err: "The image you are attempting to upload exceeds the maximum allowed size of 1MB.",
          }));
          temp = false;
        } else {
          setInputErrors((prev) => ({ ...prev, file1Err: "" }));
        }
      }
      console.log("temp", temp);
      if (!file2) {
        setInputErrors((prev) => ({
          ...prev,
          file2Err: "Please selecte banner image.",
        }));
        temp = false;
      } else {
        if (file2.size / 1024 > 1024) {
          setInputErrors((prev) => ({
            ...prev,
            file2Err: "The image you are attempting to upload exceeds the maximum allowed size of 1MB.",
          }));
          temp = false;
        } else {
          setInputErrors((prev) => ({ ...prev, file2Err: "" }));
        }
      }
      if (enableVeste) {
        if (!vestPer) {
          setInputErrors((prev) => ({
            ...prev,
            vestPerErr: "Vesting percentage is require.",
          }));
          temp = false;
        } else {
          if (Number(vestPer) < 1 || Number(vestPer) > 100) {
            setInputErrors((prev) => ({
              ...prev,
              vestPerErr: "The vesting percentage should be between 1 and 100.",
            }));
            temp = false;
          } else {
            setInputErrors((prev) => ({
              ...prev,
              vestPerErr: "",
            }));
          }
        }

        if (!vestTime) {
          setInputErrors((prev) => ({
            ...prev,
            vestTimeErr: "Vesting time is require.",
          }));
          temp = false;
        } else {
          if (Number(vestTime) <= 0) {
            setInputErrors((prev) => ({
              ...prev,
              vestTimeErr: "Vesting time must be gratherthan 0",
            }));
            temp = false;
          } else {
            setInputErrors((prev) => ({
              ...prev,
              vestTimeErr: "",
            }));
          }
        }
        if (!vestPeriod) {
          setInputErrors((prev) => ({
            ...prev,
            vestPeriodErr: "Please select vesting period.",
          }));
          temp = false;
        } else {
          setInputErrors((prev) => ({
            ...prev,
            vestPeriodErr: "",
          }));
        }
      } else {
        setInputErrors((prev) => ({
          ...prev,
          vestPerErr: "",
          vestTimeErr: "",
          vestPeriodErr: "",
        }));
      }
      if (!liquidityAmt?.token) {
        if (Number(tokenInfo?.balance) < Number(tokenCalc?.token)) {
          setError(`Insufficient ${tokenInfo?.symbol} balance.`);
          temp = false;
        } else {
          setError("");
        }
      } else {
        if (Number(tokenInfo?.balance) < Number(tokenCalc?.token) + Number(liquidityAmt?.token)) {
          setError(`Insufficient ${tokenInfo?.symbol} balance.`);
          temp = false;
        } else {
          setError("");
        }
      }

      if (temp) {
        if (networkInfo?.staking && networkInfo?.presale && networkInfo?.connecteAddress) {
          setPresaleLoader(true);

          const presaleRateWei = ethers.utils.parseUnits(presaleRate?.toString(), 18);
          const listingRateWei = ethers.utils.parseUnits(listingRate?.toString(), 18);
          const softCapWei = ethers.utils.parseUnits(softCap?.toString(), 18);
          const hardCapWei = ethers.utils.parseUnits(hardCap?.toString(), 18);
          const minContributionWei = ethers.utils.parseUnits(minContribution?.toString(), 18);
          const maxContributionWei = ethers.utils.parseUnits(maxContribution?.toString(), 18);
          const epochStartDate = new Date(startTime).getTime() / 1000;
          const epochEndDate = new Date(endTime).getTime() / 1000;
          const owner = networkInfo?.connecteAddress;
          const tokenAddress = contractAddress;

          formData.append("launchpadType", launchpadType);
          formData.append("contractAddress", contractAddress);
          formData.append("owner", networkInfo?.connecteAddress);
          formData.append("presaleRate", presaleRate);
          formData.append("listingRate", listingRate);
          formData.append("softCap", softCap);
          formData.append("hardCap", hardCap);
          formData.append("minContribution", minContribution);
          formData.append("maxContribution", maxContribution);
          formData.append("selectInterval", selectInterval); // panding
          formData.append("dex", dex?.name);
          formData.append("routeraddress", dex?.address);
          formData.append("presaleStartTime", startTime);
          formData.append("presaleEndTime", endTime);
          formData.append("website", socialMedia?.website);
          formData.append("instagram", socialMedia?.instagram);
          formData.append("facebook", socialMedia?.facebook);
          formData.append("youtube", socialMedia?.youtube);
          formData.append("tiktok", socialMedia?.tiktok);
          formData.append("twitter", socialMedia?.twitter);
          formData.append("telegram", socialMedia?.telegram);
          formData.append("description", description);
          formData.append("tokenLogoImage", file1);
          formData.append("tokenBannerImage", file2);
          formData.append("chainId", selecteChain?.chainId);
          formData.append("isTestnet", selecteChain?.testnet);
          formData.append(
            "buyTokenAddress",
            selectedTokenOne?.address
              ? selectedTokenOne?.address?.toLowerCase() === networkInfo?.wethAddress?.toLowerCase()
                ? constants.AddressZero
                : selectedTokenOne?.address
              : null
          );

          await axios
            .post(`${process.env.REACT_APP_BACKEND_URL}launchpad/insert`, formData)
            .then(async (res) => {
              let id = res.data?.id;
              if (res.data.error) {
                setError(res.data.error);
                setPresaleLoader(false);
                await axios
                  .post(`${process.env.REACT_APP_BACKEND_URL}launchpad/delete/${id}`, {
                    signature: signature?.signature,
                    message: signature?.message,
                    signerAddress: networkInfo?.connecteAddress,
                  })
                  .then(async (res) => {});
              } else {
                try {
                  setError("");
                  if (id) {
                    if (launchpadType === 0) {
                      const tx = await networkInfo?.presale.createPresale(
                        [presaleRateWei.toString(), listingRateWei, selectInterval],
                        [softCapWei, hardCapWei],
                        epochStartDate,
                        epochEndDate,
                        minContributionWei,
                        maxContributionWei,
                        owner,
                        tokenAddress,
                        enableVeste
                          ? [ethers.utils.parseUnits(vestPer.toString(), "18"), (vestPeriod?.value * Number(vestTime)).toString()]
                          : ["0", "0"],
                        dex.address
                      );
                      await tx.wait();
                      setPresaleLoader(false);
                      resetInputs();
                      navigate({
                        pathname: "/view",
                        search: `?address=${contractAddress}&type=${launchpadType}`,
                      });
                    } else {
                      let tokenIn =
                        selectedTokenOne?.address?.toLowerCase() === networkInfo?.wethAddress?.toLowerCase()
                          ? constants.AddressZero
                          : selectedTokenOne?.address;
                      const tx = await networkInfo?.staking.createStaking(
                        [
                          ethers.utils.parseUnits(presaleRate?.toString(), selectedTokenOne?.decimals),
                          ethers.utils.parseUnits(listingRate?.toString(), selectedTokenOne?.decimals),
                        ],
                        [
                          ethers.utils.parseUnits(softCap?.toString(), selectedTokenOne?.decimals),
                          ethers.utils.parseUnits(hardCap?.toString(), selectedTokenOne?.decimals),
                        ],
                        epochStartDate,
                        epochEndDate,
                        ethers.utils.parseUnits(minContribution?.toString(), selectedTokenOne?.decimals),
                        ethers.utils.parseUnits(maxContribution?.toString(), selectedTokenOne?.decimals),
                        owner,
                        tokenAddress,
                        tokenIn,
                        enableVeste
                          ? [ethers.utils.parseUnits(vestPer.toString(), "18"), (vestPeriod?.value * Number(vestTime)).toString()]
                          : ["0", "0"]
                      );
                      await tx.wait();
                      setPresaleLoader(false);
                      resetInputs();
                      navigate({
                        pathname: "/view",
                        search: `?address=${contractAddress}&type=${launchpadType}`,
                      });
                    }
                  } else {
                    setError("Something went wrong.");
                    setPresaleLoader(false);
                    await axios
                      .post(`${process.env.REACT_APP_BACKEND_URL}launchpad/delete/${id}`, {
                        signature: signature?.signature,
                        message: signature?.message,
                        signerAddress: networkInfo?.connecteAddress,
                      })
                      .then(async (res) => {});
                    setError("User rejected the request.");
                  }
                } catch (err) {
                  console.log(`error in createpresale : `, err);
                  await axios
                    .post(`${process.env.REACT_APP_BACKEND_URL}launchpad/delete/${id}`, {
                      signature: signature?.signature,
                      message: signature?.message,
                      signerAddress: networkInfo?.connecteAddress,
                    })
                    .then(async (res) => {});
                  setError("User rejected the request.");
                  setPresaleLoader(false);
                }
              }
            })
            .catch((err) => {
              console.log("error : ", err);
              setError(err.message);
              setPresaleLoader(false);
            });
        }
      }
    } catch (err) {
      console.log("error in createPresale : ", err);
      setPresaleLoader(false);
      setError(err.message);
    }
  };

  const getAndSetEstimatGas = async () => {
    // setDisplayAmountInfo()
    try {
      let contractGas = 0;
      const presaleRateWei = ethers.utils.parseUnits(presaleRate?.toString(), 18);
      const listingRateWei = ethers.utils.parseUnits(listingRate?.toString(), 18);
      const softCapWei = ethers.utils.parseUnits(softCap?.toString(), 18);
      const hardCapWei = ethers.utils.parseUnits(hardCap?.toString(), 18);
      const minContributionWei = ethers.utils.parseUnits(minContribution?.toString(), 18);
      const maxContributionWei = ethers.utils.parseUnits(maxContribution?.toString(), 18);
      const epochStartDate = new Date(startTime).getTime() / 1000;
      const epochEndDate = new Date(endTime).getTime() / 1000;
      const owner = networkInfo?.connecteAddress;
      const tokenAddress = contractAddress;
      if (launchpadType === 0) {
        const gasLimit = await networkInfo?.presale?.estimateGas.createPresale(
          [presaleRateWei, listingRateWei, selectInterval],
          [softCapWei, hardCapWei],
          epochStartDate,
          epochEndDate,
          minContributionWei,
          maxContributionWei,
          owner,
          tokenAddress,
          enableVeste ? [ethers.utils.parseUnits(vestPer.toString(), "18"), (vestPeriod?.value * Number(vestTime)).toString()] : ["0", "0"],
          dex.address
        );
        const gasPrice = await networkInfo?.provider.getGasPrice();
        const fee = gasLimit.mul(gasPrice);
        contractGas = ethers.utils.formatEther(fee);
      } else if (launchpadType === 1) {
        const gasLimit = await networkInfo?.staking?.estimateGas.createStaking(
          [presaleRateWei, listingRateWei],
          [softCapWei, hardCapWei],
          epochStartDate,
          epochEndDate,
          minContributionWei,
          maxContributionWei,
          owner,
          tokenAddress,
          enableVeste ? [ethers.utils.parseUnits(vestPer.toString(), "18"), (vestPeriod?.value * Number(vestTime)).toString()] : ["0", "0"]
        );
        const gasPrice = await networkInfo?.provider.getGasPrice();
        const fee = gasLimit.mul(gasPrice);
        contractGas = ethers.utils.formatEther(fee);
      }
      setEstimateGas(Number(contractGas));
    } catch (err) {
      // console.log("error in getDisplayData : ", err);
      setEstimateGas(0);
    }
  };

  useEffect(() => {
    if (
      contractAddress &&
      presaleRate &&
      listingRate &&
      softCap &&
      hardCap &&
      minContribution &&
      maxContribution &&
      selectInterval &&
      (launchpadType === 0 || launchpadType === 1) &&
      tokenInfo &&
      routerContract &&
      liquidityAmt &&
      networkInfo?.connecteAddress &&
      startTime &&
      endTime
    ) {
      getAndSetEstimatGas();
    }
  }, [
    contractAddress,
    presaleRate,
    listingRate,
    softCap,
    hardCap,
    minContribution,
    maxContribution,
    selectInterval,
    launchpadType,
    tokenInfo,
    routerContract,
    liquidityAmt,
    networkInfo?.connecteAddress,
    settingData?.slippagePlaceHolder,
    settingData?.deadlinePlaceHolder,
    startTime,
    endTime,
  ]);

  const checkContractAllowance = async () => {
    try {
      if (
        tokenInfo?.address &&
        networkInfo?.connecteAddress &&
        networkInfo?.presale &&
        networkInfo?.staking &&
        (launchpadType === 0 || launchpadType === 1) &&
        networkInfo?.signer
      ) {
        let allounceLiquidity = 0;
        if (launchpadType === 0) {
          allounceLiquidity = await checkAllowance(
            tokenInfo?.address,
            networkInfo?.connecteAddress,
            networkInfo?.presale,
            networkInfo?.signer
          );
        } else if (launchpadType === 1) {
          allounceLiquidity = await checkAllowance(
            tokenInfo?.address,
            networkInfo?.connecteAddress,
            networkInfo?.staking,
            networkInfo?.signer
          );
        }
        if (allounceLiquidity) {
          setContractAllowance(allounceLiquidity);
        } else {
          setContractAllowance(0);
        }
      } else {
        setContractAllowance(0);
      }
    } catch (err) {
      // console.log("error in checkContractAllowance : ", err);
      setError("Something went wrong while chekc allowance for liquidity.");
      setContractAllowance(0);
    }
  };

  useEffect(() => {
    checkContractAllowance();
  }, [
    tokenInfo?.address,
    networkInfo?.connecteAddress,
    networkInfo?.presale,
    networkInfo?.staking,
    networkInfo?.signer,
    presaleRate,
    hardCap,
    launchpadType,
    selectedTokenOne,
  ]);

  // const giveLiquidityAllowance = async () => {
  //   try {
  //     setError("");
  //     setPresaleLoader(true);
  //     if (tokenInfo?.address) {
  //       const result = await giveAllowance(tokenInfo?.address, routerContract, networkInfo?.signer);
  //       await checkLiquidityAllowance();
  //       setPresaleLoader(false);
  //     } else {
  //       setError("Provide require fields to approve contract.");
  //       setPresaleLoader(false);
  //     }
  //   } catch {
  //     setError("Something went wrong while approve liquidity contract.");
  //     setPresaleLoader(false);
  //   }
  // };

  const giveContractAllowance = async () => {
    try {
      setError("");
      setPresaleLoader(true);
      if (tokenInfo?.address) {
        if (launchpadType === 0) {
          const result = await giveAllowance(
            tokenInfo?.address,
            networkInfo?.presale,
            networkInfo?.signer,
            liquidityAmt?.token ? Math.ceil(tokenCalc?.token + 100 + Number(liquidityAmt?.token)) : Math.ceil(tokenCalc?.token + 100)
          );
          await checkContractAllowance();
          setPresaleLoader(false);
        } else if (launchpadType === 1) {
          const result = await giveAllowance(
            tokenInfo?.address,
            networkInfo?.staking,
            networkInfo?.signer,
            liquidityAmt?.token ? Math.ceil(tokenCalc?.token + 100 + Number(liquidityAmt?.token)) : Math.ceil(tokenCalc?.token + 100)
          );
          await checkContractAllowance();
          setPresaleLoader(false);
        }
      } else {
        setError("Provide require fields to approve contract.");
        setPresaleLoader(false);
      }
    } catch {
      setError("Something went wrong while approve launchpad contract.");
      setPresaleLoader(false);
    }
  };

  return (
    <>
      <Header />
      <div id="main-wrapper" className="show">
        <Sidebar />
        <div
          className={`content-body dark ${toggleSidebar ? "toggle-content-wrapper" : ""}`}
          style={{ backgroundColor: "#F5F5FF", overflowX: "hidden" }}
        >
          <div className="contained  mt-5 doted-border">
            <LaunchpadType launchpadType={launchpadType} setLaunchpadType={setLaunchpadType} />
            <TokenInformation
              contractAddress={contractAddress}
              setContractAddress={setContractAddress}
              inputErrors={inputErrors}
              setInputErrors={setInputErrors}
              tokenInfo={tokenInfo}
              setTokenInfo={setTokenInfo}
            />
            <PresaleInformation
              presaleRate={presaleRate}
              setPresaleRate={setPresaleRate}
              listingRate={listingRate}
              setListingRate={setListingRate}
              softCap={softCap}
              setSoftCap={setSoftCap}
              hardCap={hardCap}
              setHardCap={setHardCap}
              minContribution={minContribution}
              setMinContribution={setMinContribution}
              maxContribution={maxContribution}
              setMaxContribution={setMaxContribution}
              selectInterval={selectInterval}
              setSelectInterval={setSelectInterval}
              dex={dex}
              setDex={setDex}
              inputErrors={inputErrors}
              setInputErrors={setInputErrors}
              launchpadType={launchpadType}
              presaleInfo={presaleInfo}
              setPresaleInfo={setPresaleInfo}
              tokenInfo={tokenInfo}
              tokenCalc={tokenCalc}
              setTokenCalc={setTokenCalc}
              handleRouter={handleRouter}
              selectedTokenOne={selectedTokenOne}
              setSelectedTokenOne={setSelectedTokenOne}
            />
            <TokenDistribution />
            <PresaleInformation2
              startTime={startTime}
              setStartTime={setStartTime}
              endTime={endTime}
              setEndTime={setEndTime}
              inputErrors={inputErrors}
              setInputErrors={setInputErrors}
              launchpadType={launchpadType}
              enableVeste={enableVeste}
              setenableVeste={setenableVeste}
              vestPer={vestPer}
              vestTime={vestTime}
              vestPeriod={vestPeriod}
              setVestPer={setVestPer}
              setVestTime={setVestTime}
              setVestPeriod={setVestPeriod}
            />
            <ProjectInformation
              inputRef={inputRef}
              dropZoneRef={dropZoneRef}
              previewSrc={previewSrc}
              setPreviewSrc={setPreviewSrc}
              description={description}
              setDescription={setDescription}
              toggleSocialMedia={toggleSocialMedia}
              setToggleSocialMedia={setToggleSocialMedia}
              inputRef2={inputRef2}
              dropZoneRef2={dropZoneRef2}
              previewSrc2={previewSrc2}
              setPreviewSrc2={setPreviewSrc2}
              handleToggleSocialMedia={handleToggleSocialMedia}
              updateThumbnail={updateThumbnail}
              handleDrop={handleDrop}
              handleDragLeaveAndEnd={handleDragLeaveAndEnd}
              handleDragOver={handleDragOver}
              handleChangeThumbnail1={handleChangeThumbnail1}
              handleClick={handleClick}
              updateThumbnail2={updateThumbnail2}
              handleDrop2={handleDrop2}
              handleDragLeaveAndEnd2={handleDragLeaveAndEnd2}
              handleDragOver2={handleDragOver2}
              handleChangeThumbnail2={handleChangeThumbnail2}
              handleClick2={handleClick2}
              removeButtonRef={removeButtonRef}
              removeButtonRef2={removeButtonRef2}
              formats={formats}
              modules={modules}
              socialMedia={socialMedia}
              handleSocialMedia={handleSocialMedia}
              file1={file1}
              file2={file2}
              setFile1={setFile1}
              setFile2={setFile2}
              inputErrors={inputErrors}
              tokenInfo={tokenInfo}
              setTokenInfo={setTokenInfo}
              hardCap={hardCap}
              setHardCap={setHardCap}
              softCap={softCap}
              setSoftCap={setSoftCap}
            />
            <CreatePresale
              createPresale={createPresale}
              error={error}
              estimateGas={estimateGas}
              tokenCalc={tokenCalc}
              liquidityAmt={liquidityAmt}
              contractAllowance={contractAllowance}
              tokens={liquidityAmt?.token ? Math.ceil(tokenCalc?.token + Number(liquidityAmt?.token)) : Math.ceil(tokenCalc?.token)}
              launchpadType={launchpadType}
              giveContractAllowance={giveContractAllowance}
              tokenInfo={tokenInfo}
            />
          </div>
          <Footer />
        </div>
      </div>
      <PresaleModal show={presaleLoader} />
    </>
  );
}
