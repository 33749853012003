import React, { useContext, useEffect, useRef, useState } from "react";
import { centecxContext } from "../CentcexContext";
import useGetAllChainInfo from "../hooks/useGetAllChainInfo";
import { chainInfo, DEFAULT_CHAIN, SupportedChains } from "../constants/chains";
import walletConnectChains from "../constants/walletConnectChains";
import { formatChain } from "../functions/commonFunction";

const ChainDropdown = () => {
  const { networkInfo, selecteChain, setSelecteChain, chainId, isConnected } = useContext(centecxContext);
  const chains = useGetAllChainInfo();
  const [showDrop, setShowDrop] = useState(false);

  const dropdownRef = useRef();

  const switchChain = async (item) => {
    try {
      if (item) {
        if (isConnected) {
          await networkInfo?.provider?.provider.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: item?.chainIdHex }], // Switch to Ethereum Mainnet (chainId: 0x1)
          });
          let walletchain = walletConnectChains.find((item1) => item1.chainId === item.chainId);
          setSelecteChain({ ...chainInfo.get(item.chainId), ...walletchain });
          window.location.reload();
        } else {
          let walletchain = walletConnectChains.find((item1) => item1.chainId === item.chainId);
          setSelecteChain({ ...chainInfo.get(item.chainId), ...walletchain });
        }
      } else {
        if (isConnected && chainId) {
          if (SupportedChains.includes(networkInfo?.chainId)) {
            let walletchain = walletConnectChains.find((item1) => item1.chainId === chainId);
            setSelecteChain({ ...chainInfo.get(chainId), ...walletchain });
          } else {
            setSelecteChain(null);
          }
        } else {
          if (!selecteChain) {
            let walletchain = walletConnectChains.find((item1) => item1.chainId === DEFAULT_CHAIN);
            setSelecteChain({
              ...chainInfo.get(DEFAULT_CHAIN),
              ...walletchain,
            });
          }
        }
      }
    } catch (err) {
      if (err.code === 4001) {
        if (networkInfo?.isWalletConnected) {
          let walletchain = walletConnectChains.find((item1) => item1.chainId === networkInfo?.chainId);
          setSelecteChain({
            ...chainInfo.get(networkInfo?.chainId),
            ...walletchain,
          });
        }
      }
      if (err.code === 4902 && networkInfo?.isWalletConnected) {
        const foundChain = walletConnectChains?.find((item1) => item1.chainId === item.chainId);
        const chainData = {
          chainId: item.chainIdHex, // Chain ID in hexadecimal format
          chainName: foundChain.name, // Name of the network
          nativeCurrency: {
            name: foundChain.currency, // Name of the native currency
            symbol: foundChain.currency, // Symbol of the native currency
            decimals: item.decimal, // Decimals of the native currency
          },
          rpcUrls: [foundChain?.rpcUrl], // Array of RPC endpoints
          blockExplorerUrls: [foundChain?.explorerUrl], // Array of block explorer URLs
        };
        await networkInfo?.provider?.provider?.request({
          method: "wallet_addEthereumChain",
          params: [chainData],
        });
        window.location.reload();
        let walletchain = walletConnectChains.find((item1) => item1.chainId === item.chainId);
        setSelecteChain({ ...chainInfo.get(item.chainId), ...walletchain });
      }
    }
  };

  useEffect(() => {
    switchChain();
  }, [networkInfo?.tokens, chainId, networkInfo?.isWalletConnected]);

  useEffect(() => {
    let handler = (e) => {
      if (!dropdownRef?.current?.contains(e.target)) {
        setShowDrop(false);
      }
    };
    document.addEventListener("click", handler);
    return () => {
      document.removeEventListener("click", handler);
    };
  }, []);

  return (
    <li className="nav-item me-3">
      <div class="button-dropdown" ref={dropdownRef}>
        <div class="btn-group w-100">
          <button
            type="button"
            class="btn btn-outline-primary dropdown-toggle rounded-pill d-lg-block d-md-block d-none"
            onClick={() => setShowDrop(!showDrop)}
          >
            <img
              src={selecteChain?.icon ? selecteChain?.icon : "./assets/token-icons/unknown.svg"}
              width="20"
              className="me-2"
              alt=""
              style={{ width: "20px", height: "20px" }}
            />{" "}
            {selecteChain?.name ? selecteChain?.name : "Select a Network"}
          </button>
          <button
            type="button"
            class="btn btn-outline-primary dropdown-toggle rounded-pill d-lg-none d-md-none d-block"
            onClick={() => setShowDrop(!showDrop)}
          >
            <img src={selecteChain?.icon ? selecteChain?.icon : "./assets/token-icons/unknown.svg"} width="20" className="me-2" alt="" />{" "}
            {selecteChain?.name ? formatChain(selecteChain?.name) : "Select a Network"}
          </button>
          <div
            class={
              showDrop
                ? "dropdown-menu rounded-4 p-2 drop-box-2 show w-346 dropdown-chain-position"
                : "dropdown-menu rounded-4 p-2 drop-box-2"
            }
          >
            <span class="dropdown-item">Select a Network</span>
            <div class="dropdown-divider"></div>
            {chains &&
              chains.map((item) => {
                return (
                  <button class="dropdown-item" href="/" key={item.chainId} onClick={() => switchChain(item)}>
                    <img
                      src={item.icon ? item.icon : "./assets/token-icons/unknown.svg"}
                      width="20"
                      className="me-3"
                      style={{ height: "20px", width: "20px" }}
                      alt=""
                    />{" "}
                    {item.name}
                    {selecteChain?.chainId === item?.chainId ? <i className="fa fa-check-circle text-success fs-4 mx-3" /> : ""}
                  </button>
                );
              })}
          </div>
        </div>
      </div>
    </li>
  );
};

export default ChainDropdown;
